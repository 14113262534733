import React, { Component, Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from '../pages/Home';
import UserLoginPage from '../pages/Auth/UserLoginPage';
import UserRegisterPage from '../pages/Auth/UserRegisterPage';
import ForgotPasswordPage from '../pages/Auth/ForgotPasswordPage';
import ResetPasswordPage from '../pages/Auth/ResetPasswordPage';
import ContactPage from '../pages/Contact';
import ProductDetailsPage from '../pages/ProductDetails';
import OfferDetailsPage from '../pages/OfferDetails';
import NotFoundPage from '../pages/NotFound';
import FavoritePage from '../pages/Favorite';
import CartPage from '../pages/Cart';
import OrderTrackingPage from '../pages/TrakingOrder';
import AllCategoryPage from '../pages/Category';
import AllProductPage from '../pages/Product';
import AllPopularProductPage from '../pages/PopularProduct';
import AllOfferPage from '../pages/Offer';
import ProductCategoryPage from '../pages/ProductCategory';
import SearchPage from '../pages/Search';
import ProfilePage from '../pages/UserProfile';
import axios from 'axios';
import AppURL from '../api/AppURL';
import OrderListPage from '../pages/Order';

class AppRoute extends Component {
  constructor() {
    super();
    this.state = {
      user: {},
    };
  }

  componentDidMount() {
    axios
      .get(AppURL.UserData)
      .then((response) => {
        this.setUser(response.data.data);
      })
      .catch((error) => {});
  }

  setUser = (user) => {
    this.setState({ user: user });
  };

  render() {
    return (
      <Fragment>
        <Routes>
          <Route path="/" element={<HomePage user={this.state.user} setUser={this.setUser} />} />
          <Route path="/login" element={<UserLoginPage user={this.state.user} setUser={this.setUser} />} />
          <Route path="/register" element={<UserRegisterPage />} />
          <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
          <Route path="/resetpassword/:id" element={<ResetPasswordPage />} />
          <Route path="/profile" element={<ProfilePage user={this.state.user} setUser={this.setUser} />} />
          <Route path="/contact" element={<ContactPage user={this.state.user} setUser={this.setUser} />} />
          <Route path="/productdetails/:code" element={<ProductDetailsPage user={this.state.user} />} />
          <Route path="/packagedetails/:id" element={<OfferDetailsPage user={this.state.user} />} />
          <Route path="/favourite" element={<FavoritePage user={this.state.user} />} />
          <Route path="/cart" element={<CartPage user={this.state.user} setUser={this.setUser} />} />
          <Route path="/productcategory/:category" element={<ProductCategoryPage user={this.state.user} setUser={this.setUser} />} />
          <Route path="/productbysearch/:searchkey" element={<SearchPage user={this.state.user} setUser={this.setUser} />} />
          <Route path="/orderlist" element={<OrderListPage user={this.state.user} setUser={this.setUser} />} />
          <Route path="/ordertrack/:code" element={<OrderTrackingPage user={this.state.user} setUser={this.setUser} />} />
          <Route path="/allcategory" element={<AllCategoryPage user={this.state.user} setUser={this.setUser} />} />
          <Route path="/products/all" element={<AllProductPage user={this.state.user} setUser={this.setUser} />} />
          <Route path="/offers/all" element={<AllOfferPage user={this.state.user} />} />
          <Route path="/popular/product" element={<AllPopularProductPage user={this.state.user} />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Fragment>
    );
  }
}

export default AppRoute;
