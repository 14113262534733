import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang);
  };

  return (
    <div className="flex items-center space-x-2">
      <button
        onClick={() => changeLanguage('en')}
        className={`flex items-center justify-center px-4 py-1.5 font-semibold rounded-full transition-all duration-300
        ${i18n.language === 'en' ? 'bg-blue-600 text-white shadow-lg' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'}`}
      >
        <span className="mr-1 pl-1">🇺🇸</span> EN
      </button>
      <button
        onClick={() => changeLanguage('ar')}
        className={`flex items-center justify-center px-4 mx-2 py-1.5 font-semibold rounded-full transition-all duration-300
        ${i18n.language === 'ar' ? 'bg-green-600 text-white shadow-lg' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'}`}
      >
        <span className="mr-1 pl-1">🇸🇦</span> AR
      </button>
    </div>
  );
};

export default LanguageSwitcher;
