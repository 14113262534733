import React, { useState } from 'react';
import {
  Navbar,
  Container,
  Nav,
  Form,
  Dropdown,
  Offcanvas,
} from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import {
  HeartOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Badge, Flex } from 'antd';
import 'react-toastify/dist/ReactToastify.css';
import classes from './style.module.css';
import { countCart } from '../../network/cart';
import LanguageSwitcher from '../../components/Comman/LanguageSwitcher';

const NavMenuDesktop = ({ t, i18n, user }) => {
  const [searchKey, setSearchKey] = useState('');
  const [searchNavigate, setSearchNavigate] = useState(false);
  const [offcanvasOpen, setOffcanvasOpen] = useState(false);

  const { data: cartCount } = useQuery({
    queryKey: ['cartCount', user?.id],
    queryFn: countCart,
    enabled: !!user,
  });

  const handleSearchChange = (event) => {
    setSearchKey(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if (searchKey.trim().length >= 2) {
      setSearchNavigate(true);
    } else {
      toast.error(t('search_validation_error'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  if (searchNavigate) {
    return <Navigate to={`/productbysearch/${searchKey}`} />;
  }

  const renderAddToCartButton = () => (
    <Flex justify="space-between" align="center" className="mt-2" style={{ gap: '20px' }}>
      {localStorage.getItem('token') ? (
        <>
          {/* Favourite Icon */}
          <Link to="/favourite" className="text-black" style={{ textDecoration: 'none' }}>
            <HeartOutlined style={{ fontSize: '22px' }} />
          </Link>

          {/* Cart Icon with Badge */}
          <Link to="/cart" className="text-black" style={{ textDecoration: 'none' }}>
            <Badge count={cartCount} size="small">
              <ShoppingCartOutlined style={{ fontSize: '22px' }} />
            </Badge>
          </Link>

          {/* User Dropdown */}
          <Dropdown>
            <Dropdown.Toggle variant="" id="dropdown-item-button" style={{ border: 'none' }}>
              <UserOutlined style={{ fontSize: '22px' }} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <Link className={classes['mini-nav-link']} to="/profile">
                  {t('profile')}
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link className={classes['mini-nav-link']} to="/" onClick={handleLogout}>
                  {t('logout')}
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      ) : (
        <>
          <Link
            className="text-[13px] font-roboto tracking-[-0.1px] uppercase no-underline text-gray-500 font-bold"
            to="/login"
            style={{ textDecoration: 'none' }}
          >
            {t('login')}
          </Link>
          <Link
            className="text-[13px] font-roboto tracking-[-0.1px] uppercase no-underline text-gray-500 font-bold"
            to="/register"
            style={{ textDecoration: 'none' }}
          >
            {t('signup')}
          </Link>
        </>
      )}
    </Flex>
  );

  const renderNavMini = (isArabic) => (
    <Navbar bg="light">
      <Container>
        {/* Support Section */}
        <div className="flex items-center justify-end space-x-3 lg:space-x-6">
          {isArabic ? (
            <>
              <div className="d-flex align-items-center justify-content-end">
                <span className={classes["mini-nav-phone-p"]}>  {t('supportcenter')} </span>
                <span className={classes["mini-nav-phone"]} dir="rtl">
                  &#160; ٧٠٧٢٧٩٧٩ ٩٦٥+
                </span>
                <i className="fa fa-phone" style={{ marginRight: "6px", marginTop: "3px", color: "#0F63E3" }}></i>
              </div>
            </>
          ) : (
            <div className={`${classes["mini-nav-link d-flex align-items-center justify-content-end"]}`}>
              <i className="fa fa-phone" style={{ marginRight: "6px", marginTop: "3px", color: "#0F63E3" }}></i>
              <span className={classes["mini-nav-phone"]}>+965 97972707</span>
              <span className={classes["mini-nav-phone-p"]}> {t('supportcenter')}</span>
            </div>
          )}
        </div>

        {/* Navigation Links */}
        <div className="flex-1 flex items-center justify-end space-x-4 lg:space-x-6">
          <Link className={`${classes['mini-nav-link']}`}
            to="/orderlist">
            {t('Orders')}
          </Link>
          <Link className={`${classes['mini-nav-link']}`} to="/contact">
            {t('CONTACTUS')}
          </Link>

          {/* {renderLanguageButtons()} */}
          <LanguageSwitcher/>
        </div>
      </Container>
    </Navbar>
  );


  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {i18n.language === 'ar' ? renderNavMini(true) : renderNavMini(false)}
      <Navbar expand="xl" className="mb-0 p-0">
        <Container>
          {/* Logo */}
          <Link to="/" className="d-flex align-items-center">
            <img
              src={require('../../assets/images/logor.png')}
              alt="delivery-services-1"
              width="110px"
            />
          </Link>

          {/* Navbar Toggle */}
          <Navbar.Toggle aria-controls="offcanvasNavbar" />

          {/* Offcanvas Menu */}
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
            onHide={() => setOffcanvasOpen(false)}
            onShow={() => setOffcanvasOpen(true)}
          >
            <Offcanvas.Body style={{ overflow: offcanvasOpen ? 'hidden' : '' }}>
              <Nav className="justify-content-between flex-grow-1 align-items-center w-100">
                {/* Search Form */}
                <Form className="d-flex mx-auto" onSubmit={handleSearchSubmit}>
                  <input
                    type="text"
                    placeholder={t('Search')}
                    className={`${classes['search-input']} p-3`}
                    aria-label="Search"
                    onChange={handleSearchChange}
                    style={{ width: offcanvasOpen ? '320px' : '' }}
                  />
                  <button
                    id="button-addon1"
                    className={`${classes['search-button']}`}
                    type="submit"
                  >
                    <i className="fas fa-search"></i>
                  </button>
                </Form>

                {/* Icons */}
                <div className="d-flex align-items-center">
                  {user && renderAddToCartButton()}
                </div>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

    </>
  );
};

export default withTranslation()(NavMenuDesktop);

