import React, { Fragment } from "react";
import { Container, Col, Row, Card } from "react-bootstrap";
import classes from "./style.module.css";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import CategoryLoading from "../PlaceHolder/CategoryLoading";
import { queryKeys } from "../../services/react-query/queryKeys";
import { listCategories } from "../../network/category";
import { useQuery } from "@tanstack/react-query";

const getRandomColor = () => {
  const colors = ["#FEEFEA", "#F2FCE4", "#DEF9EC", "#FFF3FF", "#FFFCEB"];
  return colors[Math.floor(Math.random() * colors.length)];
};

const AllCategory = ({ t }) => {
  const { data, isLoading, error } = useQuery({
    queryKey: [queryKeys.listCategories],
    queryFn: listCategories,
  });

  if (isLoading) {
    return (
      <CategoryLoading />
    );
  }

  if (error) {
    return <div>{t('SomethingWentWrong')}</div>;
  }

  const MyView = data.data.map((item, i) => (
    <Col xl={2} lg={2} md={2} sm={12} xs={12} className="p-0 my-3" key={i.toString()}>
      <Container>
        <Card
          className={`${classes["card-categories"]} py-3 px-3 border`}
          style={{ backgroundColor: getRandomColor() }}
        >
          <Link className="custom-links" to={`/productcategory/${item.id}`} style={{ textDecoration: "none" }}>
            <Container>
              <Card.Img
                variant="top"
                src={item.image}
                alt={item.name}
                style={{ width: "100%", height: "100%", padding: "4px", borderRadius: "20px" }}
              />
            </Container>
          </Link>
          <Card.Body className="text-center px-0 pt-2 pb-0">
            <span className={`${classes["card-product-title"]}`}>
              <h4 style={{ fontWeight: "400", fontFamily: "Quicksand" }}>{item.name}</h4>
            </span>
          </Card.Body>
        </Card>
      </Container>
    </Col>
  ));

  return (
    <Fragment>
      <div className="bg-gray-100 p-10">
        <Container className="pt-5 pb-5">
          <p className={`${classes["section-header"]}`}>{t('AllCategories')}</p>
          <Row>{MyView}</Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default withTranslation()(AllCategory);
