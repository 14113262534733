import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import cogoToast from "cogo-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../services/react-query/queryKeys";
import { addCart } from "../../network/cart";
import { Card, Row, Col, Button, Flex, Typography, Badge } from 'antd';
import { MinusOutlined, PlusOutlined, ShoppingCartOutlined, ShoppingOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Meta } = Card;

const PackageDetails = ({ t, user, data }) => {
  const [quantity, setQuantity] = useState("");
  const [pageNavigateStatus, setPageNavigateStatus] = useState(false);
  const queryClient = useQueryClient();

  const onAddCart = useMutation({
    mutationFn: (formData) => addCart(formData),
    onSuccess: (data) => {
      if (data.status === true) {
        cogoToast.success(t('message2'), { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.listCart]);
      } else {
        cogoToast.warn("Something went wrong", { position: "top-right" });
      }
    },
    onError: () => {
      cogoToast.warn("Something went wrong", { position: "top-right" });
    },
  });

  const addToCart = () => {
    if (quantity.length === 0 || quantity === "0") {
      cogoToast.error(t('error2'), { position: "top-right" });
    } else if (!localStorage.getItem("token")) {
      cogoToast.warn(t('error1'), { position: "top-right" });
    } else {
      const formData = new FormData();
      formData.append("quantity", quantity);
      formData.append("item_id", data.id);
      formData.append("user_id", user.id);
      formData.append("is_product", 0);
      onAddCart.mutate(formData);
    }
  };

  const onBuyNow = useMutation({
    mutationFn: (formData) => addCart(formData),
    onSuccess: (data) => {
      if (data.status === true) {
        cogoToast.success("Purchase successful", { position: "top-right" });
        setPageNavigateStatus(true);
        queryClient.invalidateQueries([queryKeys.listCart]);
      } else {
        cogoToast.warn("Something went wrong", { position: "top-right" });
      }
    },
    onError: () => {
      cogoToast.warn("Something went wrong", { position: "top-right" });
    },
  });

  const handleBuyNow = () => {
    if (quantity.length === 0 || quantity === "0") {
      cogoToast.error(t('error2'), { position: "top-right" });
    } else if (!localStorage.getItem("token")) {
      cogoToast.warn(t('error1'), { position: "top-right" });
    } else {
      const formData = new FormData();
      formData.append("quantity", quantity);
      formData.append("item_id", data.id);
      formData.append("user_id", user.id);
      formData.append("is_product", 0);
      onBuyNow.mutate(formData);
    }
  };

  const quantityOnChange = (event) => {
    setQuantity(event.target.value);
  };

  const addQuantity = () => {
    setQuantity((prevQuantity) => Number(prevQuantity) + 1);
  };

  const subtractQuantity = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? Number(prevQuantity) - 1 : prevQuantity));
  };

  if (pageNavigateStatus) {
    return <Navigate to="/cart" />;
  }

  const { image, title, price, percentage_discount, description, product } = data;

  return (
      <div className="bg-gray-100 p-10">
        <div className="container">
          <Card hoverable>
            <Row gutter={16}>
              <Col xl={8} lg={8} md={24} sm={24}>
                <img
                  alt={title}
                  src={image}
                  style={{ width: '100%', height: '300px' }}
                />
              </Col>
              <Col xl={16} lg={16} md={24} sm={24} className="xl:mt-0 lg:mt-0 md:mt-0 mt-5" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div className="xl:text-[26px]">
                  {title}
                </div>
                <Flex className="text-gray-500">
                  {description}
                </Flex>
                <Row justify={"space-between"} >
                  <Col xl={12} lg={12} md={12} sm={12} xs={24}>
                    <div>
                      {data.new_price === "" ?
                        <Text className="text-[#0F63E3] xl:text-[30px]">{t('currency')} {price}</Text>
                        :
                        <Flex >
                          <Text className="text-[#0F63E3] xl:text-[30px]">{t('currency')} {price - (price * (percentage_discount / 100))}</Text>
                          <Text delete type="secondary" className="xl:mt-4 mx-1">{t('currency')} {price}</Text>
                          <Text type="secondary" className="xl:text-[30px]">{percentage_discount}%</Text>
                        </Flex>
                      }
                    </div>
                  </Col>
                  <Col xl={6} lg={8} md={8} sm={24} xs={24} className="xl:my-0 lg:my-0 md:my-0 my-5">
                    <Flex align="center" justify="center" className="border border-[#0F63E3] rounded-lg	p-2" >
                      <Button type="primary" onClick={subtractQuantity} shape="circle" icon={<MinusOutlined />} size={"lg"} />
                      <input
                        onChange={quantityOnChange}
                        type="number"
                        className="w-[60px] border-none text-center rounded-none placeholder-gray-500 focus:outline-none focus:shadow-none focus:border-gray-300"
                        placeholder="1"
                        min="1"
                        value={quantity}
                      />
                      <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={addQuantity} size={"lg"} />
                    </Flex>
                  </Col>
                </Row>
                <Row gutter={9}>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24} className="mb-3">
                    <Button
                      type="primary"
                      icon={<ShoppingCartOutlined />}
                      className="w-full"
                      onClick={addToCart}
                      size="large"
                    >
                      {t('cart')}
                    </Button>
                  </Col>

                  <Col xl={12} lg={12} md={12} sm={24} xs={24} className="mb-3">
                    <Button
                      type="primary"
                      className="w-full"
                      icon={<ShoppingOutlined />}
                      onClick={handleBuyNow}
                      size="large"
                    >
                      {t('buy')}
                    </Button>
                  </Col>

                </Row>
              </Col>
            </Row>
          </Card>

          <Row className="pt-5" gutter={15}>
            {product.map((item) => (
              <Col xl={6} lg={6} md={8} sm={24} xs={24} key={item.id}>
                <Link className="custom-links" to={`/productdetails/${item.id}`} style={{ textDecoration: "none" }}>
                  <Badge.Ribbon text={`Quantity: ${item.quantity}`}>
                    <Card
                      hoverable
                      className="mb-5"
                      cover={
                        <Link className="custom-links" to={`/productdetails/${item.id}`} style={{ textDecoration: "none" }}>
                          <img
                            alt="example"
                            src={item.image}
                            className="w-full"
                          />
                        </Link>
                      }
                    >
                      <Meta title={item.name} description={item.description} />
                    </Card>
                  </Badge.Ribbon>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      </div>
  );
};

export default withTranslation()(PackageDetails);
