import {axiosInstance} from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";
import i18next from "i18next";



// export const checkFavourit = catchAsync(async (product_id, user_id) => {
//   const { data } = await axiosInstance.get(`check/favourite`, {
//     params: {
//       product_id,
//       user_id,
//     }
//   });
//   return data;
// });

export const checkFavorite = async (product_id, user_id) => {
  const { data } = await axiosInstance.get(`/check/favourite?product_id=${product_id}&user_id=${user_id}`);
  return data;
};

export const listFavourites = catchAsync(async () => {
  const { data } = await axiosInstance.get('favourites', {
    headers: {
      "Accept-Language": i18next.language,
    }
  });
  return data;
});

export const addToFavorite = async (product_id, user_id) => {
  const { data } = await axiosInstance.get(`favourites/handle?product_id=${product_id}&user_id=${user_id}`);
  return data;
};

export const removeFavourite = async (id) => {
  const { data } = await axiosInstance.delete(`favourites/${id}`);
  return data;
};