import React, { Fragment } from "react";
import { Container, Col, Row, Card } from "react-bootstrap";
import classes from "./style.module.css";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import cogoToast from "cogo-toast";
import CategoryLoading from "../PlaceHolder/CategoryLoading";
import { queryKeys } from "../../services/react-query/queryKeys";
import { listProducts } from "../../network/product";
import { useMutation, useQuery } from "@tanstack/react-query";
import { addCart } from "../../network/cart";
import queryClient from "../../services/react-query/queryClient";
import { Button, Flex, Rate, Typography } from "antd";
import { ShoppingCartOutlined } from "@mui/icons-material";

const AllProduct = ({ user, t, i18n }) => {
  const { data: productData, isLoading, error } = useQuery({
    queryKey: [queryKeys.listProducts],
    queryFn: listProducts
  });
  const { Text } = Typography;

  const onAddCart = useMutation({
    mutationFn: (formData) => addCart(formData),
    onSuccess: (data) => {
      if (data.status === true) {
        cogoToast.success(t('ItemAddedToCart'), { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.listCart]);
      } else {
        cogoToast.warn(t('SomethingWentWrong'), { position: "top-right" });
      }
    },
    onError: () => {
      cogoToast.warn(t('SomethingWentWrong'), { position: "top-right" });
    },
  });

  const addToCart = (productId) => {
    if (!localStorage.getItem("token")) {
      cogoToast.warn(t('PleaseLoginFirst'), { position: "top-right" });
    } else {
      const formData = new FormData();
      formData.append("quantity", 1);
      formData.append("item_id", productId);
      formData.append("user_id", user.id);
      formData.append("is_product", 1);
      onAddCart.mutate(formData);
    }
  };

  if (isLoading) {
    return <CategoryLoading />;
  }

  if (error) {
    return <div>{t('SomethingWentWrong')}</div>;
  }

  const productView = productData?.data.map((item, i) => (
    <Col xl={2} lg={3} md={6} sm={12} xs={12} className="p-0 mt-4" key={i.toString()}>
      <Container>
        <Card className={`${classes["card-products"]} mb-3 py-3 px-3 border`}>
          <Link className="custom-links" to={`/productdetails/${item.id}`} style={{ textDecoration: "none" }}>
            <Container>
              <Card.Img className="" variant="top" src={item.image} alt={item.name} />
            </Container>
          </Link>
          <Card.Body className="px-0 pt-2 pb-0 px-2">
            <span className={`${classes["card-product-name"]}`}>
              <p>{item.name}</p>
            </span>
            <Rate disabled defaultValue={item.average_rating} />

            <Flex justify="space-between" className="mt-3">
              <div>
                {item.new_price === "" ?
                  <Text className="mx-2">{t('currency')}{item.price}</Text> :
                  <Flex justify="space-evenly">
                    <Text className="mx-2">{t('currency')}{item.new_price}</Text>
                    <Text delete type="secondary">{t('currency')}{item.price}</Text>
                  </Flex>
                }
              </div>

              <Button type="primary" onClick={() => addToCart(item.id)} icon={<ShoppingCartOutlined />} size={"lg"} />
            </Flex>
          </Card.Body>
        </Card>
      </Container>
    </Col>
  ));


  return (
    <Fragment>
      <div className="bg-gray-100 p-10">
        <Container className="pt-5 pb-5">
          <p className={`${classes["section-header"]}`}>{t('Product')}</p>

          <Row>{productView}</Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default withTranslation()(AllProduct);
