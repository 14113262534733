import React from "react";
import { Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
import CategoryLoading from "../../PlaceHolder/CategoryLoading";
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from "../../../services/react-query/queryKeys";
import { listCategories } from "../../../network/category";
import { withTranslation } from 'react-i18next'

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style, display: "block",
        paddingRight: "22px", paddingLeft: "2px", paddingTop: "5px", paddingBottom: "22px", borderRadius: "50px"
      }}
      onClick={onClick}
    >
      <style>
        {`.slick-next:before {
          color: gray;
        }`}
      </style>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        paddingRight: "22px",
        paddingLeft: "2px",
        paddingTop: "5px",
        paddingBottom: "22px",
        borderRadius: "50px",
      }}
      onClick={onClick}
    >
      <style>
        {`.slick-prev:before {
          color: gray;
        }`}
      </style>
    </div>
  );
}

function getRandomColor() {
  const colors = ["#FEEFEA", "#F2FCE4", "#DEF9EC", "#FFF3FF", "#FFFCEB"];
  return colors[Math.floor(Math.random() * colors.length)];
}

const Categories = () => {
  const { t, i18n } = useTranslation();

  const { data, isLoading, error } = useQuery({
    queryKey: [queryKeys.listCategories, i18n],
    queryFn: listCategories,
    enabled: !!i18n.language,
  });

  const settings = {
    speed: 500,
    slidesToShow: data?.data && data?.data.length < 5 ? data?.data.length : 5,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: data?.data && data?.data.length < 4 ? data?.data.length : 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: data?.data && data?.data.length < 3 ? data?.data.length : 3,
          slidesToScroll: 1,
          initialSlide: 1,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: data?.data && data?.data.length < 2 ? data?.data.length : 2,
          slidesToScroll: 1,
          initialSlide: 1,
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: data?.data && data?.data.length < 1 ? data.length : 1,
          slidesToScroll: 1,
          initialSlide: 1,
          nextArrow: false,
          prevArrow: false,
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: data?.data && data?.data.length < 1 ? data?.data.length : 1,
          slidesToScroll: 1,
          initialSlide: 1,
          nextArrow: false,
          prevArrow: false,
        }
      },
      {
        breakpoint: 280,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: false,
          prevArrow: false,
          dots: false,
        }
      }
    ]
  };

  if (isLoading) {
    return <CategoryLoading isLoading={isLoading} />;
  }

  if (error) {
    return <div>{t('Error loading categories')}</div>;
  }

  const MyView = data?.data?.map((item) => (
    <Container>
      <Card
      className="category-card"
        style={{
          backgroundColor: getRandomColor(),
          width: "120px",
          height: "120px",
          borderRadius: "50%",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Link
          className="custom-links"
          to={`/productcategory/${item.id}`}
          style={{ textDecoration: "none" }}
        >
          <Card.Img
            className=""
            variant="top"
            src={item.image}
            alt={item.name}
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "50%",
            }}
          />
           <div className="category-name-overlay">
            {item.name}
          </div>
        </Link>
      </Card>
    </Container>
  ));

  return (
    <div className="container my-20" style={{ overflow: window.innerWidth <= 920 ? "hidden" : "" }}>

      <div className="text-center mb-10 max-w-[600px] mx-auto">
        <h1 data-aos="fade-up" className="text-3xl font-bold">
          {t('category')}
        </h1>
        <p data-aos="fade-up" className="text-lg text-gray-400">
        {t('category_description')}
          <Link className="text-decoration-none" to="/allcategory">
            {t('See')}&nbsp;{t('More')}
          </Link>
        </p>
      </div>


      <div>
        <Slider className="customCard" {...settings}>{MyView}</Slider>
      </div>
    </div>
  );
};

export default withTranslation()(Categories);
