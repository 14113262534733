import React, { useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Typography
} from 'antd';
import { withTranslation } from 'react-i18next';
import { updateUser } from "../../network/auth";
import queryClient from "../../services/react-query/queryClient";
import { queryKeys } from "../../services/react-query/queryKeys";
import cogoToast from "cogo-toast";
import { useMutation } from "@tanstack/react-query";

const AccountSetting = ({ user, t }) => {
  const [form] = Form.useForm();

  const updateUserInfo = useMutation({
    mutationFn: (updatedData) => updateUser(user.id, updatedData),
    onSuccess: () => {
      cogoToast.success("User Data update", { position: "top-right" });
      queryClient.invalidateQueries([queryKeys.userData]);
      window.location.reload();
    },
    onError: () => {
      cogoToast.warn("Something went wrong", { position: "top-right" });
    },
  });

  useEffect(() => {
    form.setFieldsValue({
      name: user?.name,
      email: user?.email,
    });
  }, [user, form]);

  const onFinish = (values) => {
    updateUserInfo.mutate({ ...values });
  };

  return (
    <div className="p-10 bg-[#eff0f5]">
      <div className="container">
        <Typography.Title
          level={2}
        >
          {t('profile')}
        </Typography.Title>
        <Typography.Title
          level={4}
          type="secondary"
        >
          {t('manage')}
        </Typography.Title>
        <div className={`p-5 mb-10`} style={{
          boxSizing: " border-box",
          background: "#FFFFFF",
          border: "2px solid #ADADAD",
          borderRadius: "5px"
        }}
        >
          <Form form={form} name="basic" onFinish={onFinish} autoComplete="true"
            initialValues={{
              name: user?.name,
              email: user?.email,
            }}
          >
            <Form.Item
              label={t('name')}
              required={false}
              name="name"
              rules={[{ required: true, message: t('Please input your name!') }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="email"
              label={t('Email')}
              required={false}
              rules={[
                { type: 'email', message: t('The input is not valid E-mail!') },
                { required: true, message: t('Please input your E-mail!') },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t('UPDATEINFO')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(AccountSetting);