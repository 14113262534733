import {axiosInstance} from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";
import i18next from "i18next";


export const listOrders = catchAsync(async () => {
  const { data } = await axiosInstance.get('orders', {
    headers: {
      "Accept-Language": i18next.language,
    }
  });
  return data;
});


export const OrderDetails = async (code) => {
  const { data } = await axiosInstance.get(`orders/${code}`, {
    headers: {
      "Accept-Language": i18next.language,
    }
  });
  return data;
};

export const addOrder = catchAsync(async (formData) => {
  const { data } = await axiosInstance.post(`orders`, formData);
  return data;
});