import {axiosInstance} from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";

export const auth = catchAsync(async () => {
  const { data } = await axiosInstance.get("user");
  return data;
});

export const updateUser = catchAsync(async (id, body) => {
  const { data } = await axiosInstance.put(`updatuser/${id}`, body);
  return data;
});

export const updateUserPassword = catchAsync(async (id, password) => {
  const { data } = await axiosInstance.put(`updateuser/${id}/password`, { password });
  return data;
});

export const authLogin = catchAsync(async (body) => {
  const { data } = await axiosInstance.post("login", body);
  return data;
});

export const authRegister = catchAsync(async (body) => {
  const { data } = await axiosInstance.post("register", body);
  return data;
});

export const authForgotPassword = catchAsync(async (body) => {
  const { data } = await axiosInstance.post("/password/forgot", body);
  return data;
});

export const authResetPasswor = catchAsync(async (body) => {
  const { data } = await axiosInstance.post("/password/reset", body);
  return data;
});
