import React from "react";
import {
    Row,
    Col,
    Typography,
    Flex,
    Spin,
} from "antd";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { OrderDetails } from "../../network/order";
import { queryKeys } from "../../services/react-query/queryKeys";
import { useQuery } from "@tanstack/react-query";
import { Badge, Card, Descriptions, Steps } from 'antd';
const { Meta } = Card;
const getCurrentStep = (status) => {
    switch (status) {
        case 'Pending':
            return 0;
        case 'Processing':
            return 1;
        case 'OnDelivery':
            return 2;
        case 'Complete':
            return 3;
        default:
            return 0;
    }
};

const OrderTracking = () => {
    const { t, i18n } = useTranslation();
    const { code } = useParams();
    const { data: orderData, isLoading, error } = useQuery({
        queryKey: [queryKeys.listOrders, code, i18n],
        queryFn: () => OrderDetails(code),
        enabled: !!i18n,
    });

    if (isLoading) {
        return (
            <Flex className="my-40" align="center" gap="middle" justify="center" >
                <Spin size="large" />
            </Flex>
        );
    }

    if (error) {
        return <div>{t('Error loading order details')}</div>;
    }

    const getCurrentStatus = (status) => {
        switch (status) {
            case 'Pending':
                return t('Pendding');
            case 'Processing':
                return t('Processing');
            case 'OnDelivery':
                return t('OnDelivery');
            case 'Complete':
                return t('Complete');
            default:
                return 0;
        }
    };

    const getCurrentPayment = (payment) => {
        switch (payment) {
            case 'Visa':
                return t('Visa');
            case 'Cash':
                return t('Cash');
            default:
                return 0;
        }
    };

    const orders = orderData?.data?.map((order, i) => {
        const totalPrice = order.delivery_charge ? parseInt(order.total_price) + parseInt(order.delivery_charge) : parseInt(order.total_price);

        const items = [
            {
                key: '1',
                label: <div>{t('PaymentMethod')}</div>,
                children: getCurrentPayment(order.payment_method),
            },
            {
                key: '2',
                label: t('Ordertime'),
                children: order.order_date,
            },
            {
                key: '3',
                label: t('Status'),
                children: <Badge status={"processing"} text={getCurrentStatus(order.order_status)} />,
                span: 3,
            },
            {
                key: '7',
                label: t('OrderTotal'),
                children: order.total_price,
            },
            {
                key: '8',
                label: t('DeliveryCharge'),
                children: order.delivery_charge,
            },
            {
                key: '9',
                label: t('TotalPrice'),
                children: totalPrice,
            },
            {
                key: '10',
                label: t('DeliveryAddress'),
                children: (
                    <>
                        {order.delivery_address}
                    </>
                ),
            },
        ];

        return (
            <div className="container" key={i}>
                <Steps
                    current={getCurrentStep(order.order_status)}
                    className="mt-20 mb-12"
                    direction="horizontal"
                    items={[
                        {
                            title: t('Pendding'),
                        },
                        {
                            title: t('Processing'),
                        },
                        {
                            title: t('OnDelivery'),
                        },
                        {
                            title: t('Completed'),
                        },
                    ]}
                />

                <Descriptions title={t('OrderSummary')} bordered items={items} className="bg-white p-3 my-5" />

                <Typography.Title
                    level={4}
                    className="mt-10"
                >
                    {t('OrderContent')}
                </Typography.Title>

                <Row className="mb-20" gutter={20} >

                    {order.items.map((item) => (
                        <Col xl={6} lg={4} md={12} sm={12} key={item.item_id}>
                            <Badge.Ribbon text={item.is_product === 1 ? t('Product') : t('package')} color="blue">
                                <Card
                                    size="small"
                                    hoverable
                                    className="mt-4"
                                    cover={<img alt="example" src={item.image} style={{
                                        width: "100%",
                                        height: "300px",
                                    }} />}
                                >
                                    <Meta title={item.name} description={
                                        <div>
                                            <p className="fs-4">{t('Quantity')}: {item.quantity}</p>
                                            <p className="fs-4">{t('Itemprice')}: {item.item_total}</p>
                                        </div>
                                    } />
                                </Card>

                            </Badge.Ribbon>
                        </Col>
                    ))}
                </Row>
            </div>
        );
    });

    return (
        <div className="p-2 bg-gray-100" >
            {orders}
        </div>
    );
};

export default OrderTracking;
