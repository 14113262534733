import {axiosInstance} from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";
import i18next from "i18next";


export const listOffers = catchAsync(async () => {
  const { data } = await axiosInstance.get('packages', {
    headers: {
      "Accept-Language": i18next.language,
    }
  });
  return data;
});

export const offerDetails = async (code) => {
  const { data } = await axiosInstance.get(`packages/${code}`, {
    headers: {
      "Accept-Language": i18next.language,
    }
  });
  return data;
};
