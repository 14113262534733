class AppURL {
  static BaseURL = "https://dashboard.orderfs.online/api/v1";

  // Get Visitor Route
  static VisitorDetails = `${this.BaseURL}/visitor`;

  // Contact Page Route
  static PostContact = `${this.BaseURL}/post/contact`;

  // ========= Authentication =========
  static UserLogin = `${this.BaseURL}/login`;
  static UserData = `${this.BaseURL}/user`;
  static UserRegister = `${this.BaseURL}/register`;
  static UserForgotPassword = `${this.BaseURL}/password/forgot`;
  static UserResetPassword = `${this.BaseURL}/password/reset`;

  static ProductListByCategory(category) {
    return `${this.BaseURL}/category/${category}/products`;
  }

  // Cart
  static CartCount() {
    return `${this.BaseURL}/carts/count`;
  }

  static CartItemPlus(id, user_id, item_id) {
    return `${this.BaseURL}/cartitemplus?id=${id}&user_id=${user_id}&item_id=${item_id}`;
  }

  static CartItemMinus(id, user_id, item_id) {
    return `${this.BaseURL}/cartitemminus?id=${id}&user_id=${user_id}&item_id=${item_id}`;
  }

  // Search
  static ProductBySearch(searchKey) {
    return `${this.BaseURL}/search/${searchKey}`;
  }

  // Add Favourite
  static AddFavourite(product_id, user_id) {
    return `${this.BaseURL}/handle/favourite${product_id}?${user_id}`;
  }

  static CheckFavourite(product_id, user_id) {
    return `${this.BaseURL}/check/favourite/${product_id}?${user_id}`;
  }

  static HandleFavourite(product_id, user_id) {
    return `${this.BaseURL}/handle/favourite/${product_id}?${user_id}`;
  }

  // Favourite List
  static FavouriteList(id) {
    return `${this.BaseURL}/favouritelist/${id}`;
  }

  static RemoveFavourite(id) {
    return `${this.BaseURL}/favourites/${id}`;
  }

  // Favourite Remove
  static FavouriteRemove(product_id, user_id) {
    return `${this.BaseURL}/handle/favourite${product_id}?${user_id}`;
  }

  static Rate(rate, user_id, product_id) {
    return `${this.BaseURL}/ratings?rate=${rate}&user_id=${user_id}&product_id=${product_id}`;
  }
}

export default AppURL;
