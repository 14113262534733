import React, { Component, Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";

class CategoryLoading extends Component {
  render() {
    let isLoading = this.props.isLoading;

    return (
      <Fragment>
        <div className={isLoading}>
          <Container>
            <Row>
              <Col xl={4} lg={4} md={6} sm={6} xs={12} className="p-0">
                <div className={`${this.props.loaderDiv} `}>
                  <div className="ph-item">
                    <div className="ph-col-12">
                      <div className="ph-picture"></div>
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={4} md={6} sm={12} xs={12} className="p-0">
                <div className={`${this.props.loaderDiv} mx-2`}>
                  <div className="ph-item">
                    <div className="ph-col-12">
                      <div className="ph-picture"></div>
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={4} md={6} sm={12} xs={12} className="p-0">
                <div className={`${this.props.loaderDiv} `}>
                  <div className="ph-item">
                    <div className="ph-col-12">
                      <div className="ph-picture"></div>
                      <div className="ph-row">
                        <div className="ph-col-12"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
             
             
            </Row>
          </Container>
        </div>
      </Fragment>
    );
  }
}

export default CategoryLoading;
