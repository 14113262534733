import React, { Component, Fragment } from "react";
import Offer from "../../components/Offer";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

class AllOfferPage extends Component {
  render() {
    const User = this.props.user;
    return (
      <Fragment>
        <Navbar user={User} />
        <Offer user={User} />
        <Footer />
      </Fragment>
    );
  }
}

export default AllOfferPage;
