import React, { Fragment } from "react";
import { Container, Col, Row, Card } from "react-bootstrap";
import classes from "./style.module.css";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { useQuery } from "@tanstack/react-query";
import OfferLoading from "../PlaceHolder/OfferLoading";
import { queryKeys } from "../../services/react-query/queryKeys";
import { listOffers } from "../../network/offer";

const AllOffer = ({ t, i18n }) => {
  const { data, isLoading, error } = useQuery({
    queryKey: [queryKeys.listOffers],
    queryFn: listOffers,
  });

  if (isLoading) {
    return (
      <OfferLoading />
    );
  }

  if (error) {
    return <div>{t('SomethingWentWrong')}</div>;
  }

  const MyView = data?.data.map((item, i) => (
    <Col xl={6} lg={6} md={12} sm={12} xs={12} className={`my-4 ${i18n.language === 'ar' ? 'text-right' : ''}`} key={i.toString()}>
      <Container>
        <Link className="custom-links" to={`/packagedetails/${item.id}`}>
          <Card className="text-white" style={{ height: "220px", border: "none" }}>
            <Card.Img src={item.image} alt="Card image" style={{ height: "100%", objectFit: "cover" }} />
            <Card.ImgOverlay>
              <p className={classes["caption"]}>{item.title}</p>
            </Card.ImgOverlay>
          </Card>
        </Link>
      </Container>
    </Col>
  ));

  return (
    <Fragment>
      <div className="bg-gray-100 p-10">
        <Container className="pt-5 pb-5">
          <p className={classes["section-header"]}>{t('package')}</p>
          <Row>{MyView}</Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default withTranslation()(AllOffer);
