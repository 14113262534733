import React from "react";
import { Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { withTranslation } from 'react-i18next';
import cogoToast from "cogo-toast";
import Slider from "react-slick";
import CategoryLoading from "../../PlaceHolder/CategoryLoading";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import classes from "./style.module.css";
import { listPopularProducts } from "../../../network/product";
import { queryKeys } from "../../../services/react-query/queryKeys";
import { addCart } from "../../../network/cart";
import { Button, Flex, Rate, Typography } from "antd";
import { ShoppingCartOutlined } from "@mui/icons-material";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style, display: "block",
        paddingRight: "22px", paddingLeft: "2px", paddingTop: "5px", paddingBottom: "22px", borderRadius: "50px"
      }}
      onClick={onClick}
    >
      <style>
        {`.slick-next:before {
          color: gray;
        }`}
      </style>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        borderRadius: "50px",
      }}
      onClick={onClick}
    >
      <style>
        {`.slick-prev:before {
          color: gray;
        }`}
      </style>
    </div>
  );
}

const PopularProduct = ({ t, i18n, user }) => {
  const queryClient = useQueryClient();
  const { Text } = Typography;

  const { data: productData, isLoading } = useQuery({
    queryKey: [queryKeys.listPopularProducts, i18n],
    queryFn: listPopularProducts,
    enabled: !!i18n,
  });

  const onAddCart = useMutation({
    mutationFn: (formData) => addCart(formData),
    onSuccess: (data) => {
      if (data.status === true) {
        cogoToast.success("Product added successfully", { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.listCart]);
      } else {
        cogoToast.warn("Something went wrong", { position: "top-right" });
      }
    },
    onError: () => {
      cogoToast.warn("Something went wrong", { position: "top-right" });
    },
  });

  const addToCart = (productId) => {
    if (!localStorage.getItem("token")) {
      cogoToast.warn("Please Login first to be able to add items.", {
        position: "top-right",
      });
    } else {
      const formData = new FormData();
      formData.append("quantity", 1);
      formData.append("item_id", productId);
      formData.append("user_id", user.id);
      formData.append("is_product", 1);
      onAddCart.mutate(formData);
    }
  };

  const sliderWidth =
    productData?.data && productData?.data.length === 1 && window.innerWidth >= 800
      ? "20%"
      : productData?.data && productData?.data.length === 2 && window.innerWidth >= 800
        ? "40%"
        : productData?.data && productData?.data.length === 3 && window.innerWidth >= 800
          ? "60%"
          : productData?.data && productData?.data.length === 4 && window.innerWidth >= 400
            ? "80%"
            : "100%";

  const sliderStyles = {
    width: sliderWidth,
  };

  const settings = {
    speed: 500,
    slidesToShow: productData?.data && productData?.data.length < 5 ? productData?.data.length : 5,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: productData && productData.length < 4 ? productData.length : 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: productData && productData.length < 3 ? productData.length : 3,
          slidesToScroll: 1,
          initialSlide: 1,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: productData?.data && productData?.data.length < 2 ? productData?.data.length : 2,
          slidesToScroll: 1,
          initialSlide: 1,
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: productData?.data && productData?.data.length < 1 ? productData.length : 1,
          slidesToScroll: 1,
          initialSlide: 1,
          nextArrow: false,
          prevArrow: false,
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: productData?.data && productData?.data.length < 1 ? productData?.data.length : 1,
          slidesToScroll: 1,
          initialSlide: 1,
          nextArrow: false,
          prevArrow: false,
        }
      },
      {
        breakpoint: 280,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: false,
          prevArrow: false,
          dots: false,
        }
      }
    ]
  };

  if (isLoading) {
    return <CategoryLoading />;
  }


  const MyView = productData?.data && productData?.data.map((item, i) => {
    return (

      <Container>
        <Card
          className={`${classes["card-products"]} py-3 px-3 border ${i18next.language === "ar" ? "text-right" : ""}`}
        >
          <Link
            className="custom-links"
            to={"/productdetails/" + item.id}
            style={{ textDecoration: "none" }}
          >
            <Container>
              <Card.Img
                className=""
                variant="top"
                src={item.image}
                alt={item.name}
              />
            </Container>
          </Link>
          <Card.Body
            className="px-0 pt-2 pb-0 px-2"
          >
            <span className={`${classes["card-product-name"]}`}>
              <p>{item.name}</p>
            </span>
            <Rate disabled defaultValue={item.average_rating} />

            <Flex justify="space-between" className="mt-3">
              <div>
                {item.new_price === "" ?
                  <Text className="mx-2">{t('currency')}{item.price}</Text> :
                  <Flex justify="space-evenly">
                    <Text className="mx-2">{t('currency')}{item.new_price}</Text>
                    <Text delete type="secondary">{t('currency')}{item.price}</Text>
                  </Flex>
                }
              </div>

              <Button type="primary" onClick={() => addToCart(item.id)} icon={<ShoppingCartOutlined />} size={"lg"} />
            </Flex>
          </Card.Body>
        </Card>
      </Container>

    );
  });

  return (
    <div className="container my-20" style={{ overflow: window.innerWidth <= 920 ? "hidden" : "" }}>
      <div className="text-center mb-10 max-w-[600px] mx-auto">
        <h1 data-aos="fade-up" className="text-3xl font-bold">
          {t('PopularProduct')}
        </h1>
        <p data-aos="fade-up" className="text-lg text-gray-400">
        {t('popular_product_description')}  <Link className="text-decoration-none" to="/popular/product">
            {t('See')}&nbsp;{t('More')}
          </Link>
        </p>
      </div>

      <div style={sliderStyles}>
        <Slider {...settings}>{MyView}</Slider>
      </div>
    </div>
  );
};

export default withTranslation()(PopularProduct);
