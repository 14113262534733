import { axiosInstance } from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";
import i18next from "i18next";


export const listProducts = catchAsync(async () => {
  const { data } = await axiosInstance.get('products', {
    headers: {
      "Accept-Language": i18next.language,
    }
  });
  return data;
});


export const listPopularProducts = catchAsync(async () => {
  const { data } = await axiosInstance.get('popular/products', {
    headers: {
      "Accept-Language": i18next.language,
    }
  });
  return data;
});

export const listCategoryProducts = async (code) => {
  const { data } = await axiosInstance.get(`categories/${code}/products`, {
    headers: {
      "Accept-Language": i18next.language,
    }
  });
  return data;
};

export const productDetails = async (code) => {
  const { data } = await axiosInstance.get(`products/${code}`, {
    headers: {
      "Accept-Language": i18next.language,
    }
  });
  return data;
};

