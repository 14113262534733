import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useQuery } from '@tanstack/react-query';
import { listOffers } from "../../../network/offer";
import { queryKeys } from "../../../services/react-query/queryKeys";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
import OfferLoading from "../../PlaceHolder/OfferLoading";

const Hero = ({ i18n, handleOrderPopup }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2300,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    cssEase: "ease-in-out",
    pauseOnHover: false,
    pauseOnFocus: true,
  };

  const { data, isLoading, error } = useQuery({
    queryKey: [queryKeys.listOffers, i18n],
    queryFn: listOffers,
    enabled: !!i18n,
  });

  if (isLoading) {
    return (
        <OfferLoading isLoading={isLoading} />
    );
  }

  if (error) {
    return <div>Error loading offers. Please try again later.</div>;
  }

  const offerData = data?.data || [];
  const isRTL = i18n.language === 'ar';

  return (
    <div className="relative overflow-hidden min-h-[550px] sm:min-h-[650px] bg-gray-100 flex justify-center items-center dark:bg-gray-950 dark:text-white duration-200">
      <div className="container pb-8 sm:pb-0">
        <Slider {...settings}>
          {offerData.map((data, index) => (
            <div>
              <div className={`grid grid-cols-1 sm:grid-cols-2 ${isRTL ? 'sm:flex-row-reverse' : ''}`}>
                <div className={`flex flex-col justify-center gap-4 pt-12 sm:pt-0 text-center sm:text-left order-2 sm:order-${isRTL ? '2' : '1'} relative z-10 ${isRTL ? 'sm:text-right' : ''}`}>
                  <h1
                    data-aos="zoom-out"
                    data-aos-duration="800"
                    data-aos-once="true"
                    className="text-5xl sm:text-6xl lg:text-7xl font-extrabold tracking-tight leading-tight text-primary dark:text-secondary"
                  >
                    {data.title}
                  </h1>
                  <p
                    data-aos="fade-up"
                    data-aos-duration="800"
                    data-aos-delay="200"
                    className="text-lg sm:text-xl text-gray-600 dark:text-gray-300"
                  >
                    {data.description}
                  </p>
                  <div
                    data-aos="fade-up"
                    data-aos-duration="800"
                    data-aos-delay="400"
                    className={`pt-12 sm:pt-0 text-center `}
                  >
                     <Link to={`/packagedetails/${data.id}`} key={index}>
                      <button
                        onClick={handleOrderPopup}
                        className="bg-gradient-to-r  from-blue-400 to-blue-600 hover:from-blue-500 hover:to-blue-700 hover:scale-105 transition-transform duration-300 ease-in-out text-white py-3 px-6 rounded-full font-semibold shadow-lg"
                      >
                        {t('order_now')}
                      </button>
                    </Link>
                    <Link to="/offers/all">
                      <button className="bg-gradient-to-r mx-4 from-gray-500 to-gray-700 hover:from-gray-600 hover:to-gray-800 hover:scale-105 transition-transform duration-300 ease-in-out text-white py-3 px-6 rounded-full font-semibold shadow-lg">
                        {t('show_more_packages')}
                      </button>
                    </Link>
                  </div>
                </div>
                <div className={`order-${isRTL ? '1' : '2'} sm:order-${isRTL ? '1' : '2'}`}>
                  <div
                    data-aos="zoom-in"
                    data-aos-once="true"
                    className="relative z-10"
                  >
                    <img
                      src={data.image}
                      alt={data.title}
                      className={`w-[300px] h-[300px] sm:h-[450px] sm:w-[450px] sm:scale-105 lg:scale-120 object-contain ${isRTL ? 'mx-8' : 'mx-auto'}`}
                    />
                  </div>
                </div>
              </div>
            </div>

          ))}
        </Slider>
      </div>
    </div>
  );
};

export default withTranslation()(Hero);
