import React, { Fragment } from "react";
import { Container, Col, Row, Card } from "react-bootstrap";
import classes from "./style.module.css";
import axios from "axios";
import AppURL from "../../api/AppURL";
import { Link } from "react-router-dom";
import cogoToast from "cogo-toast";
import { withTranslation } from 'react-i18next';
import { Button, Empty, Flex, Rate, Typography } from "antd";
import { ShoppingCartOutlined } from "@mui/icons-material";

const Category = ({ t, user, ProductData }) => {
  const { Text } = Typography;

  const addToCart = (productId) => {
    const quantity = 1;

    if (!localStorage.getItem("token")) {
      cogoToast.warn(t('PleaseLoginFirst'), { position: "top-right" });
    } else {
      const user_id = user?.id;
      const MyFormData = new FormData();
      MyFormData.append("quantity", quantity);
      MyFormData.append("item_id", productId);
      MyFormData.append("user_id", user_id);
      MyFormData.append("is_product", 1);

      axios.post(AppURL.AddToCart, MyFormData)
        .then((response) => {
          if (response?.data?.status === true) {
            cogoToast.success(t('message2'), { position: "top-right" });
          } else {
            cogoToast.warn(t('SomethingWentWrong'), { position: "top-right" });
          }
        })
        .catch(() => {
          cogoToast.warn(t('SomethingWentWrong'), { position: "top-right" });
        });
    }
  };

  if (ProductData?.length === 0) {
    return (
      <div className="grid content-center bg-gray-100 h-[380px]">
        <Empty description={
          <Typography.Text>
            {t('no_data')}
          </Typography.Text>
        }
        />
      </div>
    );
  }

  return (
    <Fragment>
      <div className="bg-gray-100 p-10">
        <Container>
          <p className={`${classes["section-header"]}`}>{t('cp')}</p>
          <Row>
            {ProductData?.map((item, i) => (
              <Col xl={2} lg={3} md={6} sm={12} xs={12} className="p-0 mt-4" key={i.toString()}>
                <Container>
                  <Card className={`${classes["card-products"]} mb-3 py-3 px-3 border`}>
                    <Link className="custom-links" to={`/productdetails/${item.id}`} style={{ textDecoration: "none" }}>
                      <Container>
                        <Card.Img variant="top" src={item.image} alt={item.name} />
                      </Container>
                    </Link>
                    <Card.Body className="px-0 pt-2 pb-0 px-2">
                      <span className={`${classes["card-product-name"]}`}>
                        <p>{item.name}</p>
                      </span>
                      <Rate disabled defaultValue={item.average_rating} />

                      <Flex justify="space-between" className="mt-3">
                        <div>
                          {item.new_price === "" ?
                            <Text className="mx-2">{t('currency')}{item.price}</Text> :
                            <Flex justify="space-evenly">
                              <Text className="mx-2">{t('currency')}{item.new_price}</Text>
                              <Text delete type="secondary">{t('currency')}{item.price}</Text>
                            </Flex>
                          }
                        </div>

                        <Button type="primary" onClick={() => addToCart(item.id)} icon={<ShoppingCartOutlined />} size={"lg"} />
                      </Flex>
                    </Card.Body>
                  </Card>
                </Container>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default withTranslation()(Category);
