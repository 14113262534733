import React, { Component, Fragment } from "react";
import { Col, Container, Row, Card, Form, Button } from "react-bootstrap";
import classes from "./UserLogin.module.css";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import AppURL from "../../api/AppURL";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cogoToast from 'cogo-toast';
import { withTranslation } from 'react-i18next'

class UserLogin extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      message: "",
      loggedIn: false,
    };
  }

  formSubmitHandler(event) {
    event.preventDefault();
    const data = {
      email: this.state.email,
      password: this.state.password,
    };



      axios
      .post(AppURL.UserLogin, data)
      .then((response) => {
        if (response.data.status === true) {
          localStorage.setItem("token", response.data.token);
          this.setState({ loggedIn: true });

          // this.setState({
          //   PageNavigateStatus: true,
          // });
          window.location.reload();
        } else {
          cogoToast.error("Something went wrong!", {
            position: "top-right",
          });
          this.setState({
            PageRefreshStatus: true,
          });
        }
      })
      .catch((error) => {});
  }


  render() {
    const { t } = this.props;

    // Navigate After Login
    if (this.state.loggedIn) {
      return <Navigate to="/" />;
    }
    
    // Authorization
    if (localStorage.getItem("token")) {
      return <Navigate to="/" />;
    }

    return (
      <Fragment>
        <div className={`${classes["auth-wrapper"]}`}>
          <Container>
            <Row>
              <Col className="mx-auto py-5" xl={8} lg={8} md={8} sm={12}>
                <h4 className={`${classes["auth-header-title"]}`}>
                  {t("login1")}
                </h4>
                <h6 className={`${classes["auth-subheader-title"]}`}>
                {t("login2")}{" "}
                  <Link
                    to="/register"
                    className={`${classes["register-link"]}`}
                  >
                    {t("signup")}
                    
                  </Link>{" "}
              
                  {t("login7")}
                </h6>

                <Card className={`${classes["custom-card"]}`}>
                  <Card.Body>
                    <Row>
                      <Col></Col>
                      <Col xl={6}>
                        <Form onSubmit={this.formSubmitHandler.bind(this)}>
                          <Form.Group className="mb-3" controlId="email">
                            <Form.Label className={`${classes["form-label"]}`}>
                          
                              {t("login3")}

                            </Form.Label>
                            <Form.Control
                              className={`${classes["form-inputs"]}`}
                              type="email"
                              placeholder={t("login3")}
                              onChange={(e) => {
                                this.setState({ email: e.target.value });
                              }}
                            />
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="password">
                            <Form.Label className={`${classes["form-label"]}`}>
                        
                              {t("login4")}

                            </Form.Label>
                            <Form.Control
                              id="passwordField"
                              className={`${classes["form-inputs"]}`}
                              type="password"
                              placeholder={t("login4")}
                              onChange={(e) => {
                                this.setState({ password: e.target.value });
                              }}
                            />
                          </Form.Group>
                          <Form.Text>
                            <Link
                              className={`${classes["forgot-link"]}`}
                              to="/forgotpassword"
                            >
                              {t("login6")}

                            </Link>
                          </Form.Text>
                          <Button
                            type="submit"
                            className={`${classes["auth-button"]}`}
                          >
                            
                            {t("login")}

                          </Button>
                        </Form>
                      </Col>
                      <Col></Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Start React Toastify */}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          closeButton={false}
        />
        {/* End React Toastify */}
      </Fragment>
    );
  }
}


export default withTranslation()(UserLogin);

