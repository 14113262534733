import React, { Fragment } from "react";
import {
  Container,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import classes from "./style.module.css";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import cogoToast from "cogo-toast";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from "../../services/react-query/queryKeys";
import { addCart } from "../../network/cart";
import { Button, Empty, Flex, Rate, Typography } from "antd";
import { ShoppingCartOutlined } from "@mui/icons-material";

const SearchList = ({ ProductData, user }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { Text } = Typography;

  const onAddCart = useMutation({
    mutationFn: (formData) => addCart(formData),
    onSuccess: (data) => {
      if (data.status === true) {
        cogoToast.success("Product added successfully", { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.listCart]);
      } else {
        cogoToast.warn("Something went wrong", { position: "top-right" });
      }
    },
    onError: () => {
      cogoToast.warn("Something went wrong", { position: "top-right" });
    },
  });

  const addToCart = (productId) => {
    if (!localStorage.getItem("token")) {
      cogoToast.warn("Please Login first to be able to add items.", {
        position: "top-right",
      });
    } else {
      const formData = new FormData();
      formData.append("quantity", 1);
      formData.append("item_id", productId);
      formData.append("user_id", user.id);
      formData.append("is_product", 1);
      onAddCart.mutate(formData);
    }
  };

  if (ProductData?.length === 0) {
    return (
      <div className="grid content-center bg-gray-100 h-[380px]">
        <Empty description={
          <Typography.Text>
            {t('no_data')}
          </Typography.Text>
        }
        />
      </div>
    );
  }

  const MyView = ProductData?.map((product, i) => (
    <Col
      xl={2}
      lg={3}
      md={6}
      sm={12}
      xs={12}
      className={`mb-2 p-1`}
      key={i}
    >
      <Container>
        <Card className={`${classes["card-products"]} py-3 px-3 border`}>
          <div className="position-relative">
            <Card.Img className="img-fluid" src={product.image} alt={product.name} />
          </div>
          <Card.Body className="px-0 pt-2 pb-0 px-2">
            <Link className="custom-links" to={`/productdetails/${product.id}`} style={{ textDecoration: "none" }}>
              <span className={classes["card-product-name"]}>
                <p>{product.name}</p>
              </span>
              <Rate disabled defaultValue={product.average_rating} />

            </Link>
            <Flex justify="space-between" className="mt-3">
              <div>
                {product.new_price === "" ?
                  <Text className="mx-2">{t('currency')}{product.price}</Text> :
                  <Flex justify="space-evenly">
                    <Text className="mx-2">{t('currency')}{product.new_price}</Text>
                    <Text delete type="secondary">{t('currency')}{product.price}</Text>
                  </Flex>
                }
              </div>

              <Button type="primary" onClick={() => addToCart(product.id)} icon={<ShoppingCartOutlined />} size={"lg"} />
            </Flex>
          </Card.Body>
        </Card>
      </Container>
    </Col>
  ));

  return (
    <Fragment>
      <div className="bg-gray-100 p-10">
        <Container>
          <p className={classes["section-header"]}>{t('searchpageh')}</p>
          <p className={classes["section-p"]}>{t('searchpagep')}</p>
          <Row>{MyView}</Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default SearchList;
