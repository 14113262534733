import React, { Component, Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import classes from "./style.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import AppURL from "../../api/AppURL";
import { withTranslation } from 'react-i18next';
import { Flex } from "antd";

class FooterDesktop extends Component {
  constructor() {
    super();
    this.state = {
      address: "",
      android_app_link: "",
      ios_app_link: "",
      facebook_link: "",
      twitter_link: "",
      instagram_link: "",
      copyright_text: "",
      loaderDiv: "",
      mainDiv: "d-none",
    };
  }

  componentDidMount() {
    axios
      .get(AppURL.SiteInfo)
      .then((response) => {
        let StatusCode = response.status;
        if (StatusCode === 200) {
          let JsonData = response.data[0];
          this.setState({
            address: JsonData["address"],
            android_app_link: JsonData["android_app_link"],
            ios_app_link: JsonData["ios_app_link"],
            facebook_link: JsonData["facebook_link"],
            twitter_link: JsonData["twitter_link"],
            instagram_link: JsonData["instagram_link"],
            copyright_text: JsonData["copyright_text"],
            loaderDiv: "d-none",
            mainDiv: "",
          });
        }
      })
      .catch((error) => { });
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <Fragment>
        {/* Start Footer One */}
        <div className="mt-5" >
          <Container >
            <Row>
              <Col xl={4} lg={4} md={4} sm={4} xs={12} className="mb-5">

                <Row>
                  <Col xl={2} lg={3} md={4} sm={5} xs={3}>
                    <img
                      src={require("../../assets/images/common/footer/svg3.png")}
                      alt="delivery-services-1"
                      width="65px"
                    />
                  </Col>
                  <Col xl={10} lg={9} md={8} sm={7} xs={9}>
                    <h4>{t('f1')}</h4>
                    <p className="fs-5">{t('f2')}</p>
                  </Col>


                </Row>
              </Col>
              <Col xl={4} lg={4} md={4} sm={4} xs={12} className="mb-5">

                <Row >
                  <Col xl={2} lg={3} md={4} sm={5} xs={3}>
                    <img
                      src={require("../../assets/images/common/footer/svg1.png")}
                      alt="delivery-services-1"
                      width="65px"
                    />

                  </Col>
                  <Col xl={10} lg={9} md={8} sm={7} xs={9}>
                    <h4>{t('f3')} </h4>
                    <p className="fs-5">{t('f4')}</p>
                  </Col>


                </Row>
              </Col>
              <Col xl={4} lg={4} md={4} sm={4} xs={12} className="mb-5">

                <Row className="mb-4">
                  <Col xl={2} lg={3} md={4} sm={5} xs={3}>
                    <img
                      src={require("../../assets/images/common/footer/svg2.png")}
                      alt="delivery-services-1"
                      width="65px"
                    />
                  </Col>
                  <Col xl={10} lg={9} md={8} sm={7} xs={9} >
                    <h4>{t('f5')}</h4>
                    <p className="fs-5">{t('f6')}</p>
                  </Col>


                </Row>
              </Col>

            </Row>
            <hr></hr>
          </Container>
        </div>
        {/* End Footer One */}

        {/* Start Footer Two */}
        <div className={`${classes["footer-one"]}`}>
          <Container >
            <Row>
              <Col xl={7} lg={12} md={12} sm={12} className="mb-4 ">
                <h1 className="mb-5">    <img
                  src={require("../../assets/logo_w.png")}
                  alt="delivery-services-1"
                  width="140px"
                />
                </h1>

                <p className="fs-4">   <i class="fa fa-map-marker" aria-hidden="true" style={{ paddingRight: "10px" }}></i>
                  {t('Address')}: C.R. No. 337167 -P.O.Box: 2106 Safat 13022 Kuwait</p>
                {i18n.language === 'ar' ? (
                  <>
                    <p className="fs-4"> <i className="fas fa-phone" style={{ paddingRight: "10px" }}></i> {t('CallUs')}: ٢٤٨٢٣٨٨٧ ٩٦٥+</p>
                  </>
                ) : (
                  <p className="fs-4"> <i className="fas fa-phone" style={{ paddingRight: "10px" }}></i> {t('CallUs')}: +965 24823887</p>
                )}
                <p className="fs-4">   <i class="fas fa-envelope" style={{ paddingRight: "10px" }}></i> {t('Email')}: info@order-fs.online</p>

              </Col>
              <Col xl={3} lg={6} md={6} sm={6} xs={6} className="mb-4 ">
                <h1>{t('Account')}</h1>
                <p className="fs-4"> <Link className={`${classes["mini-nav-link"]} text-decoration-none text-dark`} to="/favourite">
                  {t('Wishlist')}
                </Link></p>

                <p className="fs-4"> <Link className={`${classes["mini-nav-link"]} text-decoration-none text-dark`} to="/cart">
                  {t('Mycart')}
                </Link></p>

                <p className="fs-4"> <Link className={`${classes["mini-nav-link"]} text-decoration-none text-dark`} to="/orderlist">
                  {t('TrackOrder')}
                </Link></p>
                <p className="fs-4"> <Link className={`${classes["mini-nav-link"]} text-decoration-none text-dark`} to="/orderlist">
                  {t('Orders')}
                </Link></p>


              </Col>

              <Col xl={2} lg={6} md={6} sm={6} xs={6}>
                <h1>{t('HelpCenter')}</h1>
                <p className="fs-4"> <Link className={`${classes["mini-nav-link"]} text-decoration-none text-dark`} to="/">
                  {t('AboutUs')}
                </Link></p>
                <p className="fs-4"> <Link className={`${classes["mini-nav-link"]} text-decoration-none text-dark`} to="/contact">
                  {t('CONTACTUS')}
                </Link></p>
                <p className="fs-4"> <Link className={`${classes["mini-nav-link"]} text-decoration-none text-dark`} to="/">
                  {t('PrivacyPolicy')}
                </Link></p>
              </Col>

            </Row>
            <hr></hr>
          </Container>

        </div>

        {/* End Footer Two */}

        {/* Start Footer Three */}
        <div className={`${classes["footer-three"]}`}>
          <Container className={`${classes["responsive-footer"]} `}>
            <Row>
              <Col className="mt-5" xl={6} lg={6} md={12} sm={12}>
                <Link target="_blank" to="https://techiesonic.com" className="text-decoration-none">
                  <p className="fs-4 text-black" >
                    {t('Copy')}
                  </p>
                </Link>
              </Col>
              <Col className="mt-5" xl={3} lg={3} md={12} sm={12}>

                <img
                  src={require("../../assets/images/common/footer/Payment.png")}
                  alt="delivery-services-2"
                  width="142px"
                />
              </Col>
              <Col className="mt-5" xl={3} lg={3} md={12} sm={12}>
                <Flex>
                  <a
                    href={this.state.facebook_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className={`${classes["social-icon"]} img-fluid me-1`}
                      src={require("../../assets/images/common/footer/social/facebook.png")}
                      alt="facebook.png"
                      width="30px"
                    />
                  </a>

                  <a
                    href={this.state.twitter_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className={`${classes["social-icon"]} img-fluid me-1`}
                      src={require("../../assets/images/common/footer/social/twitter.png")}
                      alt="twitter.png"
                      width="30px"
                    />
                  </a>

                  <a
                    href={this.state.instagram_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className={`${classes["social-icon"]} img-fluid me-1`}
                      src={require("../../assets/images/common/footer/social/instagram.png")}
                      alt="instagram.png"
                      width="30px"
                    />
                  </a>

                  <img
                    className={`${classes["social-icon"]} img-fluid me-1`}
                    src={require("../../assets/images/common/footer/social/youtube.png")}
                    alt="youtube.png"
                    width="30px"
                  />
                </Flex>

              </Col>
            </Row>
          </Container>
        </div>
        {/* End Footer Three */}
      </Fragment >
    );
  }
}


export default withTranslation()(FooterDesktop);
