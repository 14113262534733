import React, { Component, Fragment } from "react";
import { Col, Container, Row, Card, Form, Button } from "react-bootstrap";
import classes from "./UserRegister.module.css";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import AppURL from "../../api/AppURL";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from 'react-i18next'


class UserRegister extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      password: "",
      phone: "",
      message: "",
      loggedIn: false,
    };
    this.inputName = this.inputName.bind(this);
    this.inputEmail = this.inputEmail.bind(this);
    this.inputPassword = this.inputPassword.bind(this);
    this.inputPhone = this.inputPhone.bind(this);
    this.formSubmitHandler = this.formSubmitHandler.bind(this);
  }

  inputName(event) {
    this.setState({ name: event.target.value });
  }

  inputEmail(event) {
    this.setState({ email: event.target.value });
  }
  inputPassword(event) {
    this.setState({ password: event.target.value });
  }
  inputPhone(event) {
    this.setState({ phone: event.target.value });
  }


  formSubmitHandler(event) {
    event.preventDefault();
    const data = {
      name: this.state.name,
      phone: this.state.phone,
      email: this.state.email,
      password: this.state.password,
    };
    axios
      .post(AppURL.UserRegister, data)
      .then((response) => {
        this.setState({ loggedIn: true });
        this.props.history.push('/login');
      })
      .catch((error) => {
         if (this.state.name.length === 0) {
          toast.error("Last Name field is required");
        } else if (this.state.email.length === 0) {
          toast.error("Email field is required");
        } else if (this.state.password.length === 0) {
          toast.error("Password field is required");
        }
      });
  }

  render() {
    const { t } = this.props;

    // Navigate After Register
    if (this.state.loggedIn) {
      return <Navigate to="/login" />;
    }
    // Authorization
    if (localStorage.getItem("token")) {
      return <Navigate to="/login" />;
    }

    return (
      <Fragment>
        <div className={`${classes["auth-wrapper"]}`}>
          <Container>
            <Row>
              <Col className="mx-auto py-5" xl={8} lg={8} md={8} sm={12}>
                <h4 className={`${classes["auth-header-title"]}`}>
                 
                     {t('signup1')}
                </h4>
                <h6 className={`${classes["auth-subheader-title"]}`}>
                  {t('signup2')}{" "}
                  <Link to="/login" className={`${classes["register-link"]}`}>
                  {t('login')}
                  </Link>{" "}
                  
                  {t('login7')}
                </h6>

                <Card className={`${classes["custom-card"]}`}>
                  <Card.Body>
                    <Row>
                      <Col></Col>
                      <Col xl={8}>
                        <Form onSubmit={this.formSubmitHandler}>
                          <Row>
                          
                              <Form.Group className="mb-3" controlId="phone">
                                <Form.Label
                                  className={`${classes["form-label"]}`}
                                >
                                  {t('name')}
                                </Form.Label>
                                <Form.Control
                                  className={`${classes["form-inputs"]}`}
                                  type="text"
                                  placeholder={t('name')}
                                  onChange={this.inputName}
                                />
                              </Form.Group>
                        

                           
                          </Row>
                          <Row>
                          
                              <Form.Group className="mb-3" controlId="phone">
                                <Form.Label
                                  className={`${classes["form-label"]}`}
                                >
                                  
                                  {t('phone')}
                                </Form.Label>
                                <Form.Control
                                  className={`${classes["form-inputs"]}`}
                                  type="text"
                                  placeholder={t('phone')}
                                  onChange={this.inputPhone}
                                />
                              </Form.Group>
                        

                           
                          </Row>

                          <Form.Group className="mb-3" controlId="phone">
                            <Form.Label className={`${classes["form-label"]}`}>
                             {t('login3')}
                            </Form.Label>
                            <Form.Control
                              className={`${classes["form-inputs"]}`}
                              type="email"
                              placeholder={t('login3')}
                              onChange={this.inputEmail}
                            />
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="phone">
                            <Form.Label className={`${classes["form-label"]}`}>
                            {t('login4')}
                            </Form.Label>
                            <Form.Control
                              id="passwordField"
                              className={`${classes["form-inputs"]}`}
                              type="password"
                              placeholder={t('login4')}
                              onChange={this.inputPassword}
                            />
                          </Form.Group>

                          <Button
                            type="submit"
                            className={`${classes["auth-button"]}`}
                          >
                              {t('signup')}
                          </Button>
                        </Form>
                      </Col>
                      <Col></Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Start React Toastify */}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          closeButton={false}
        />
        {/* End React Toastify */}
      </Fragment>
    );
  }
}


export default withTranslation()(UserRegister);

