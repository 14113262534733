import React, { useEffect, useState } from "react";
import cogoToast from "cogo-toast";
import { useNavigate } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../services/react-query/queryKeys";
import { addCart } from "../../network/cart";
import { addToFavorite, checkFavorite } from "../../network/favourite";
import { Card, Row, Col, Rate, Button, Flex, Typography } from 'antd';
import { HeartFilled, MinusOutlined, PlusOutlined, ShoppingCartOutlined, ShoppingOutlined } from "@ant-design/icons";
import { rating } from "../../network/rate";

const { Text } = Typography;
const ProductDetails = ({ data, user, t }) => {
  const [quantity, setQuantity] = useState("");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  const onRating = useMutation({
    mutationFn: ({ rate, user_id, product_id }) => rating(rate, user_id, product_id),
    onSuccess: (data) => {
      if (data.status === true) {
        cogoToast.success(t('rateUpdatedSuccess'), { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.listProducts, data?.id, user?.id]);
        window.location.reload();
      } else {
        cogoToast.error(t('somethingWentWrong'), { position: "top-right" });
      }
    },
    onError: () => {
      cogoToast.error(t('somethingWentWrong'), { position: "top-right" });
    },
  });

  const addRating = (rate) => {
    if (!localStorage.getItem("token")) {
      cogoToast.warn(t('error1'), { position: "top-right" });
    } else {
      let product_id = data?.id;
      let user_id = user?.id;
      onRating.mutate({ rate, user_id, product_id });
    }
  };

  const onAddCart = useMutation({
    mutationFn: (formData) => addCart(formData),
    onSuccess: (data) => {
      if (data.status === true) {
        cogoToast.success("Product added successfully", { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.listCart]);
      } else {
        cogoToast.warn("Something went wrong", { position: "top-right" });
      }
    },
    onError: () => {
      cogoToast.warn("Something went wrong", { position: "top-right" });
    },
  });

  const addToCart = () => {
    if (quantity.length === 0 || quantity === "0") {
      cogoToast.error(t('error2'), { position: "top-right" });
    } else if (!localStorage.getItem("token")) {
      cogoToast.warn(t('error1'), { position: "top-right" });
    } else {
      const formData = new FormData();
      formData.append("quantity", quantity);
      formData.append("item_id", data?.id);
      formData.append("user_id", user?.id);
      formData.append("is_product", 1);
      onAddCart.mutate(formData);
    }
  };

  const onBuyNow = useMutation({
    mutationFn: (formData) => addCart(formData),
    onSuccess: (data) => {
      if (data.status === true) {
        cogoToast.success("Purchase successful", { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.listCart]);
        navigate('/cart');
      } else {
        cogoToast.warn("Something went wrong", { position: "top-right" });
      }
    },
    onError: () => {
      cogoToast.warn("Something went wrong", { position: "top-right" });
    },
  });

  const buyNowHandler = () => {
    if (quantity.length === 0 || quantity === "0") {
      cogoToast.error(t('error2'), { position: "top-right" });
    } else if (!localStorage.getItem("token")) {
      cogoToast.warn(t('error1'), { position: "top-right" });
    } else {
      const formData = new FormData();
      formData.append("quantity", quantity);
      formData.append("item_id", data?.id);
      formData.append("user_id", user?.id);
      formData.append("is_product", 1);
      onBuyNow.mutate(formData);
    }
  };

  const onAddToFavorite = useMutation({
    mutationFn: ({ product_id, user_id }) => addToFavorite(product_id, user_id),
    onSuccess: (data) => {
      if (data.isfavorite === true) {
        cogoToast.success("Added to favorites", { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.favorite, data?.id, user?.id]);

      } else {
        cogoToast.success("Remove to favorites", { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.favorite, data?.id, user?.id]);
      }
    },
    onError: () => {
      cogoToast.error("Something went wrong", { position: "top-right" });
    },
  });

  const addToFavourite = () => {
    if (!localStorage.getItem("token")) {
      cogoToast.warn(t('error1'), { position: "top-right" });
    } else {
      let product_id = data?.id;
      let user_id = user?.id;
      onAddToFavorite.mutate({ product_id, user_id });
    }
  };

  const { data: favoriteData, isLoading: favoriteLoading } = useQuery({
    queryKey: [queryKeys.favorite, data?.id, user?.id],
    queryFn: () => checkFavorite(data?.id, user?.id),
    enabled: !!user?.id && !!data?.id,
  });

  const quantityOnChange = (event) => {
    setQuantity(event.target.value);
  };

  const addQuantity = () => {
    setQuantity((prevQuantity) => Number(prevQuantity) + 1);
  };

  const subtractQuantity = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? Number(prevQuantity) - 1 : prevQuantity));
  };

  return (
      <div className="bg-gray-100 p-10">
        <div className="container">
          <Card hoverable>
            <Row gutter={16}>
              <Col xl={8} lg={8} md={24} sm={24}>
                <img
                  alt={data?.name}
                  src={data?.image}
                  style={{ width: '100%', height: '300px' }}
                />
              </Col>
              <Col xl={16} lg={16} md={24} sm={24} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Row justify="space-between" className="mb-5">
                  <Col>
                    <div className="xl:text-[26px]">
                      {data?.name}
                    </div>
                  </Col>
                  <Col>
                    <HeartFilled
                      className={`${favoriteData?.isfavorite ? 'text-red-500' : 'text-gray-400'}`}
                      style={{ fontSize: '30px' }}
                      onClick={addToFavourite}
                      isLoading={favoriteLoading}
                    />
                  </Col>
                </Row>

                <Flex className="text-gray-500">
                  {data?.description}
                </Flex>

                <Flex className="my-5">
                  <Rate name="rate"
                    size={25}
                    defaultValue={data?.average_rating}
                    precision={0.5}
                    onChange={(newValue) => addRating(newValue)}
                  />
                </Flex>

                <Row justify={"space-between"} className="my-2">
                  <Col className="xl:my-0 lg:my-0 md:my-0 my-2" xl={12} lg={12} md={12} sm={12} xs={24}>
                    <div>
                      {data?.new_price === "" ?
                        <Text className="text-[#0F63E3] xl:text-[30px]">{t('currency')} {data?.price}</Text>
                        :
                        <Flex >
                          <Text className="text-[#0F63E3] xl:text-[30px]">{t('currency')} {data?.new_price}</Text>
                          <Text delete type="secondary" className="xl:mt-5 mx-1">{t('currency')} {data?.price}</Text>
                          <Text type="secondary" className="xl:text-[30px]">{Math.round(((data?.price - data?.new_price) / data?.price) * 100)}%</Text>
                        </Flex>
                      }
                    </div>
                  </Col>
                  <Col className="xl:my-0 lg:my-0 md:my-0 my-2" xl={7} lg={8} md={8} sm={12} xs={24} >
                    <Flex align="center" justify="center" className="border border-[#0F63E3] rounded-lg p-2" >
                      <Button type="primary" onClick={subtractQuantity} shape="circle" icon={<MinusOutlined />} size={"lg"} />
                      <input
                        onChange={quantityOnChange}
                        type="number"
                        className="xl:w-[100px] w-[60px] border-none text-center rounded-none placeholder-gray-500 focus:outline-none focus:shadow-none focus:border-gray-300"
                        placeholder="1"
                        min="1"
                        value={quantity}
                      />
                      <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={addQuantity} size={"lg"} />
                    </Flex>
                  </Col>
                </Row>

                <Row gutter={9} className="my-4">
                  <Col xl={12} lg={12} md={12} sm={24} xs={24} className="mb-2">
                    <Button
                      type="primary"
                      icon={<ShoppingCartOutlined />}
                      className="w-full"
                      onClick={addToCart}
                      size="large"
                    >
                      {t('cart')}
                    </Button>
                  </Col>

                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Button
                      type="primary"
                      className="w-full mb-3"
                      icon={<ShoppingOutlined />}
                      onClick={buyNowHandler}
                      size="large"
                    >
                      {t('buy')}
                    </Button>
                  </Col>

                </Row>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
  );
};

export default withTranslation()(ProductDetails);
