import React, { Fragment } from "react";
import { Row, Col, Button, Form, Input, Typography } from "antd";
import { useTranslation } from 'react-i18next';
import { contactUs } from "../../network/contact";
import 'react-toastify/dist/ReactToastify.css';
import { useMutation } from "@tanstack/react-query";
import cogoToast from "cogo-toast";

const Contact = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onContact = useMutation({
    mutationFn: (formData) => contactUs(formData),
    onSuccess: (data) => {
        cogoToast.success(t("Message sent successfully"), { position: "top-right" });
    },
    onError: () => {
      cogoToast.warn(t("Something went wrong"), { position: "top-right" });
    },
  });

  const onFinish = (values) => {
    onContact.mutate(values);
  };

  return (
    <Fragment>
      <div className="py-5 bg-gray-100">
        <div className="container">
          <Typography.Title level={4}>{t('CONTACTUS')}</Typography.Title>
          <Row gutter={20}>
            <Col className="mb-4" xl={16} lg={16} md={24} sm={24} xs={24}>
              <Form
                form={form}
                name="contact"
                onFinish={onFinish}
                layout="vertical"
              >
                <Form.Item
                  name="name"
                  label={t('name')}
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: t('Please input your name!'),
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="email"
                  label={t('email')}
                  required={false}
                  rules={[
                    {
                      type: 'email',
                      message: t('The input is not valid E-mail!'),
                    },
                    {
                      required: true,
                      message: t('Please input your E-mail!'),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="message"
                  label={t('message')}
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: t('Please input your message!'),
                    },
                  ]}
                >
                  <Input.TextArea showCount maxLength={100} />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="w-full">
                    {t('Send')}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
            <Col className="mb-4" xl={8} lg={8} md={24} sm={24} xs={24}>
              <iframe
                title="Lazapee Location Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7726.506553389561!2d121.42226903665936!3d14.470140574603013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397ede77c57074d%3A0xcde0b295efdad3a3!2sMunicipal%20Hall%20of%20Santa%20Maria%2C%20Laguna!5e0!3m2!1sen!2sph!4v1653659092336!5m2!1sen!2sph"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                loading="lazy"
              ></iframe>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

export default Contact;
