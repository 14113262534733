import { axiosInstance } from "../services/axiosInstance";
import i18next from "i18next";


export const productSearch = async (key) => {
  const { data } = await axiosInstance.get(`products/search/${key}`, {
    headers: {
      "Accept-Language": i18next.language,
    }
  });
  return data;
};

