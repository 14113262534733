import {axiosInstance} from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";
import i18next from "i18next";


export const listCart = catchAsync(async () => {
  const { data } = await axiosInstance.get('carts', {
    headers: {
      "Accept-Language": i18next.language,
    }
  });
  return data;
});

export const addCart = catchAsync(async (body) => {
  const { data } = await axiosInstance.post("carts", body);
  return data;
});


export const countCart = catchAsync(async () => {
  const { data } = await axiosInstance.get('carts/count', {
    headers: {
      "Accept-Language": i18next.language,
    }
  });
  return data;
});

export const removeItem = catchAsync(async (params) => {
  const { data } = await axiosInstance.get('/delete/carts', {params});
  return data;
});

export const itemPlus = catchAsync(async (params) => {
  const { data } = await axiosInstance.get(`/carts/item/increment`, {
    params
  });
  return data;
});

export const itemMinus = catchAsync(async (params) => {
  const { data } = await axiosInstance.get(`/carts/item/decrement`, {
    params
  });
  return data;
});

