import React, { useEffect, useState } from "react";
import cogoToast from "cogo-toast";
import { Navigate, useNavigate } from "react-router-dom";
import { withTranslation } from 'react-i18next'
import { listCart, removeItem, itemPlus, itemMinus } from "../../network/cart";
import { queryKeys } from "../../services/react-query/queryKeys";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addOrder } from "../../network/order";
import {
  Button, Empty, Flex, Typography, Row, Card,
  Col, Form,
  Input,
  Select,
} from "antd";
import { DeleteFilled, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import CartLoading from "../PlaceHolder/CartLoading";
import aesjs from 'aes-js';
import hesabeCrypt from "hesabe-crypt";
import axios from "axios";

const { Option } = Select;

const Cart = ({ user, t, i18n }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [paymentObject, setPaymentObject] = useState(null);
  const [secret] = useState("gq6JWP7kZYmN85AqYqb8V32yb14B9XnM");
  const ivKey = "ZYmN85AqYqb8V32y";
  const accessCode = "c63ee50b-6f64-4e55-bd19-875c3be7fbe6";
  const merchantCode = "87720923";
  const responseUrl = "https://api.hesabe.com/customer-response?id=87720923";
  const failureUrl = "https://api.hesabe.com/customer-response?id=87720923";
  const version = "2.0";

  let totalPriceSum = 0;

  const { data: cartData, isLoading } = useQuery({
    queryKey: [queryKeys.listCart, i18n],
    queryFn: listCart,
    enabled: !!i18n,
  });

  const initiateCheckout = async () => {
    if (!paymentObject) {
      console.error("Payment object is not initialized.");
      return;
    }

    const requestPayload = {
      merchantCode,
      amount: totalPriceSum,
      paymentType: 0,
      responseUrl,
      failureUrl,
      version,
      orderReferenceNumber: `User: ${user.id}`,
      name: user.name,
      email: user.email,
      phone: user.phone,
    };

    try {
      const encryptedData = paymentObject.encryptAes(JSON.stringify(requestPayload));
      const response = await axios.post("https://api.hesabe.com/checkout", { data: encryptedData }, {
        headers: {
          accessCode,
          "Content-Type": "application/json",
        },
      });

      if (response.data) {
        handlePayment(response.data);
      } else {
        cogoToast.error(t("error.emptyResponse"), { position: "top-right" });
      }
    } catch (error) {
      console.error("Error initiating checkout: ", error);
      cogoToast.error(error.message || t("error.generic"), { position: "top-right" });
    }
  };

  const handlePayment = (encryptedResponse) => {
    try {
      const decryptedData = paymentObject.decryptAes(encryptedResponse);
      const result = JSON.parse(decryptedData);

      if (result.status === false) {
        cogoToast.error(`${t("error.code")}: ${result.code}`, { position: "top-right" });
      } else {
        const paymentData = result.response.data;
        window.location.href = `https://api.hesabe.com/payment?data=${paymentData}`;
      }
    } catch (error) {
      console.error("Error decrypting payment data: ", error);
      cogoToast.error(t("error.decryption"), { position: "top-right" });
    }
  };

  useEffect(() => {
    const key = aesjs.utils.utf8.toBytes(secret);
    const iv = aesjs.utils.utf8.toBytes(ivKey);
    setPaymentObject(new hesabeCrypt(key, iv));
  }, [secret, ivKey]);  

  const removeItemMutation = useMutation({
    mutationFn: (params) => removeItem(params),
    onSuccess: (data) => {
      if (data.status === "true") {
        cogoToast.success(t('message5'), {
          position: "top-right",
        });
        queryClient.invalidateQueries([queryKeys.listCart]);
      } else {
        cogoToast.error("Something went wrong!", {
          position: "top-right",
        });
      }
    },
    onError: () => {
      cogoToast.error("Something went wrong!", {
        position: "top-right",
      });
    },
  });

  const itemPlusHandler = useMutation({
    mutationFn: (params) => itemPlus(params),
    onSuccess: (data) => {
      if (data.status === true) {
        cogoToast.success(t('message3'), {
          position: "top-right",
        });
        queryClient.invalidateQueries([queryKeys.listCart]);
      } else {
        cogoToast.error("Something went wrong!", {
          position: "top-right",
        });
      }
    },
    onError: () => {
      cogoToast.error("Something went wrong!", {
        position: "top-right",
      });
    },
  });

  const itemMinusHandler = useMutation({
    mutationFn: (params) => itemMinus(params),
    onSuccess: (data) => {
      if (data?.status === true) {
        cogoToast.success(t('message4'), {
          position: "top-right",
        });
        queryClient.invalidateQueries([queryKeys.listCart]);
      } else {
        cogoToast.error("Something went wrong!", {
          position: "top-right",
        });
      }
    },
    onError: () => {
      cogoToast.error("Something went wrong!", {
        position: "top-right",
      });
    },
  });

  const { mutate: onBuyNow, isLoading: isMutating } = useMutation({
    mutationFn: async (formData) => await addOrder(formData),
    onSuccess: async (data) => {
      if (data?.status === "true") {
        if (data?.pay_url) {
          window.location.href = data.pay_url;
        } else {
          cogoToast.success(t("orderReceived"), {
            position: "top-right",
          });
          queryClient.invalidateQueries([queryKeys.listOrders]);
          navigate("/orderlist");
        }
      } else {
        cogoToast.warn(t("somethingWrong"), {
          position: "top-right",
        });
      }
    },
    onError: () => {
      cogoToast.warn(t("somethingWrong"), {
        position: "top-right",
      });
    },
  });

  const onFinish = async (values) => {
    if (values.payment_method === "visa") {
      initiateCheckout();
    } else {
      onBuyNow(values);
    }
  };

  if (!localStorage.getItem("token")) {
    return <Navigate to="/login" />;
  }

  if (isLoading) {
    return <CartLoading isLoading={isLoading} />;
  }

  if (cartData?.data?.length === 0) {
    return (
      <div className="grid content-center bg-gray-100 h-[380px]">
        <Empty description={
          <Typography.Text>
            {t('cartma')} <a href="/">{t('addnow')}</a>
          </Typography.Text>
        }
        />
      </div>
    );
  }

  const MyView = cartData?.data?.map((CartList) => {
    totalPriceSum = totalPriceSum + parseInt(CartList.total_price);
    return (
      <Row gutter={20} className="mb-12">
        <Col xl={3} lg={3} md={8} sm={24} xs={24} className="mb-3">
          <img
            src={CartList?.items?.image}
            alt="product1"
            width="100px"
          />
        </Col>
        <Col xl={16} lg={6} md={8} sm={24} xs={24}>
          <div>
            <span className="text-[14px] text-black">
              {CartList.items?.name ?? CartList.items?.title}
            </span>
            <br />
            <span className="xl:text-[14px] text-[#757575]">
              {t('Quantity')}: {CartList?.quantity}
            </span>
            <br />
            <span className="text-[14px] text-[#757575]">
              {t('Price')}:
              <span className="mx-1">
                {CartList.is_product === 0 ? (
                  <>
                    <Typography.Text className="text-[#0F63E3]">{t('currency')}{CartList.items.price - (CartList.items.price * (CartList.items.percentage_discount / 100))}</Typography.Text>
                    <Typography.Text delete type="secondary" className="mt-10 mx-1">{t('currency')} {CartList.items.price}</Typography.Text>
                    <Typography.Text className="text-[#0F63E3]" type="secondary">{CartList.items.percentage_discount}%</Typography.Text>
                  </>
                ) : (
                  CartList.items.new_price === "" ?
                    <Typography.Text className="text-[#0F63E3]">{t('currency')}{CartList.items.price}</Typography.Text> :
                    <>
                      <Typography.Text className="text-[#0F63E3]">{t('currency')}{CartList.items.new_price}</Typography.Text>
                      <Typography.Text delete type="secondary" className="mx-2">{t('currency')}{CartList.items.price}</Typography.Text>
                    </>
                )}
              </span>
            </span>
            <br />
            <span className="text-[14px] text-[#757575]">
              {t('TotalPrice')} :
              <span className="text-[14px] text-[#0F63E3] mx-1">
                {t('currency')}{CartList?.total_price}
              </span>
            </span>
          </div>
        </Col>
        <Col xl={4} lg={3} md={8} sm={24} xs={24}>
          <Row>
            <Col>
              <div className="xl:text-[14px] text-black mb-4">
                {t('Quantity')}
              </div>
              <Flex>
                <Button type="primary" onClick={() =>
                  itemPlusHandler.mutate({
                    id: CartList.id,
                    user_id: CartList.user_id,
                    item_id: CartList.item_id,
                    is_product: CartList.is_product,
                  })
                } icon={<PlusOutlined />} size="small" />
                <Button onClick={() =>
                  itemMinusHandler.mutate({
                    id: CartList.id,
                    user_id: CartList.user_id,
                    item_id: CartList.item_id,
                    is_product: CartList.is_product,
                  })
                } className="mx-2" type="primary" icon={<MinusOutlined />} size="small" />

                <DeleteFilled className="text-red-500 mx-4" onClick={() =>
                  removeItemMutation.mutate({
                    id: CartList.id,
                    user_id: CartList.user_id,
                    item_id: CartList.item_id,
                  })
                } style={{ fontSize: "24px" }} />
              </Flex>
            </Col>
          </Row>

        </Col>
        <hr />
      </Row>
    );
  });

  return (
    <div className="p-10 bg-gray-100">
      <div className="container">
        <Row gutter={17}>
          <Col xl={18} lg={18} md={16} sm={24} xs={24} className="mb-10" >
            <Card className="border-none" title={t('Mycart')}>
              {MyView}
            </Card>
          </Col>
          <Col xl={6} lg={6} md={8} sm={24} xs={24} >
            <Card className="border-none" title={t('OrderSummary')}>
              <Form form={form} onFinish={onFinish}>
                <Form.Item
                  name="delivery_address"
                  rules={[
                    {
                      required: true,
                      message: 'Please input address',
                    },
                  ]}
                >
                  <Input.TextArea placeholder={t('Address')} showCount maxLength={100} />
                </Form.Item>

                <Form.Item
                  name="payment_method"
                  rules={[
                    {
                      required: true,
                      message: 'Please select payment!',
                    },
                  ]}
                >
                  <Select placeholder={t('SelectPaymentMethod')}>
                    <Option value="cash">{t('Cash')}</Option>
                    <Option value="visa">{t('Visa')}</Option>
                  </Select>
                </Form.Item>

                <span className="text-[12px] text-[#757575]">
                  {t('TotalItems')}: {cartData?.data.length}
                </span>
                <br />
                <span className="text-[12px] text-[#757575]">
                  {t('TotalPrice')}: {t('currency')}
                  <span className="text-[12px] text-[#757575]" >
                    {totalPriceSum}
                  </span>
                </span>
                <hr />

                <Button loading={isMutating} type="primary" htmlType="submit" className="w-full mt-5">
                  {t('CheckOut')}
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default withTranslation()(Cart);

