import React, { Component, Fragment } from "react";
import AppURL from "../../api/AppURL";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Categories from "../../components/Home/Category";
import Products from "../../components/Home/Product";
import Hero from "../../components/Home/Hero";
import PopularProducts from "../../components/Home/PopularProduct";
import Lastsection from "../../components/Home/LastSection";
import axios from "axios";

class HomePage extends Component {
  GetVisitorDetails = () => {
    axios.get(AppURL.VisitorDetails).then().catch();
  };

  componentDidMount() {
    this.GetVisitorDetails();
  }
  render() {
    const User = this.props.user;
    return (
      <Fragment>
        <Navbar user={User} />
        <Hero user={User} />
        <Categories user={User} />
        <Products user={User} />
        <PopularProducts user={User} />
        <Lastsection user={User} />
        <Footer />
      </Fragment>
    );
  }
}

export default HomePage;
