import React, { Fragment, useState } from "react";
import { Container, Row, Col, Card, Spinner } from "react-bootstrap";
import classes from "./style.module.css";
import axios from "axios";
import AppURL from "../../api/AppURL";
import cogoToast from "cogo-toast";
import { Navigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CategoryLoading from "../PlaceHolder/CategoryLoading";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { listFavourites } from "../../network/favourite";
import { queryKeys } from "../../services/react-query/queryKeys";
import { addCart } from "../../network/cart";
import { Button, Empty, Flex, Rate, Typography } from "antd";
import { ShoppingCartOutlined } from "@mui/icons-material";

const Favorite = ({ user }) => {
  const { t } = useTranslation();
  const [removeToFav, setRemoveToFav] = useState(false);
  const [PageRefreshStatus, setPageRefreshStatus] = useState(false);
  const queryClient = useQueryClient();
  const { Text } = Typography;

  const { data: productData, isLoading } = useQuery({
    queryKey: [queryKeys.listFavorites],
    queryFn: listFavourites
  });

  const removeItem = async (id) => {
    setRemoveToFav(true);
    try {
      await axios.delete(AppURL.RemoveFavourite(id));
      cogoToast.success(t('message1'), { position: "top-right" });
      setRemoveToFav(false);
      queryClient.invalidateQueries([queryKeys.listFavorites]);
      setPageRefreshStatus(true);
    } catch (error) {
      cogoToast.error("Something went wrong!", { position: "top-right" });
      setRemoveToFav(false);
      setPageRefreshStatus(true);
    }
  };

  const onAddCart = useMutation({
    mutationFn: (formData) => addCart(formData),
    onSuccess: (data) => {
      if (data.status === true) {
        cogoToast.success("Product added successfully", { position: "top-right" });
        queryClient.invalidateQueries([queryKeys.listCart]);
      } else {
        cogoToast.warn("Something went wrong", { position: "top-right" });
      }
    },
    onError: () => {
      cogoToast.warn("Something went wrong", { position: "top-right" });
    },
  });

  const addToCart = (productId) => {
    if (!localStorage.getItem("token")) {
      cogoToast.warn("Please Login first to be able to add items.", {
        position: "top-right",
      });
    } else {
      const formData = new FormData();
      formData.append("quantity", 1);
      formData.append("item_id", productId);
      formData.append("user_id", user.id);
      formData.append("is_product", 1);
      onAddCart.mutate(formData);
    }
  };

  if (!localStorage.getItem("token")) {
    return <Navigate to="/login" />;
  }

  if (isLoading) {
    return (
      <Fragment>
        <div className="bg-gray-100 p-10">
          <Container>
            <Row> <CategoryLoading /></Row>
          </Container>
        </div>
      </Fragment>
    );
  }

  if (!productData.data || productData.data.length === 0) {
    return (
      <div className="grid content-center bg-gray-100 h-[380px]">
        <Empty description={
          <Typography.Text>
            {t('no_data')}
          </Typography.Text>
        }
        />
      </div>
    );
  }

  const MyView = productData?.data.map((item, i) => (
    <Col xl={2} lg={3} md={6} sm={12} xs={12} className={`mb-2 p-1`} key={i.toString()}>
      <Container>
        <Card className={`${classes["card-products"]} py-3 px-3 border`}>
          <Card.Img className="img-fluid" src={item.product.image} alt={item.product.name} />
          <Card.ImgOverlay>
            <p className={`${classes["caption"]}`}>
              <Button onClick={() => removeItem(item.id)} className={`${classes["custom-button"]} float-end`}>
                <i className="fas fa-heart px-1 py-1" style={{ fontSize: "15px" }}></i>{" "}
                {removeToFav && (
                  <Spinner size="sm" animation="border" role="status" />
                )}
              </Button>
            </p>
          </Card.ImgOverlay>
          <Card className="px-0 pt-2 pb-0 px-2" style={{ border: "none" }}>
            <Link className="custom-links" to={"/productdetails/" + item.product.id} style={{ textDecoration: "none" }}>
              <span className={`${classes["card-product-name"]}`}>
                <p>{item.product.name}</p>
              </span>

              <Rate disabled defaultValue={item.product.rate} />

            </Link>

            <Flex justify="space-between" className="mt-3">
              <div>
                {item.new_price === "" ?
                  <Text className="mx-2">{t('currency')}{item?.product.price}</Text> :
                  <Flex justify="space-evenly">
                    <Text className="mx-2">{t('currency')}{item?.product.new_price}</Text>
                    <Text delete type="secondary">{t('currency')}{item?.product.price}</Text>
                  </Flex>
                }
              </div>

              <Button type="primary" onClick={() => addToCart(item.id)} icon={<ShoppingCartOutlined />} size={"lg"} />
            </Flex>
          </Card>
        </Card>
      </Container>
    </Col>
  ));

  return (
    <Fragment>
      <div className="bg-gray-100 p-10">
        <Container>
          <p className={`${classes["section-header"]}`}>{t('headerfov')}</p>
          <p className={`${classes["section-p"]}`}>{t('pfov')}</p>
          <Row>{MyView}</Row>
        </Container>
      </div>
      {PageRefreshStatus && <Navigate to={window.location} />}
    </Fragment>
  );
};

export default Favorite;
